import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
// import CsvDownloader from "react-csv-downloader";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import { useRecoilState } from "recoil";
import { loadingAtom } from "../atoms/atoms";

import axios from "axios";
import apiConfig from "../api/apiConfig";

const fileTypes = ["CSV", "csv"];
const Files = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);

	const [newSetFile, setNewSetFile] = useState(null);
	const [uploadFile, setUploadFile] = useState(null);
	const [dataFile, setDataFile] = useState(null);
	const [providerNew, setProviderNew] = useState(null);
	const [providerUpload, setProviderUpload] = useState(null);

	const [newProducts, setNewProducts] = useState(null);
	const [updatedProducts, setUpdatedProducts] = useState(null);
	const [dataProcessedProductsCounter, setDataProcessedProductsCounter] =
		useState(null);
	const [dataUpdatedProducts, setDataUpdatedProducts] = useState(null);

	const [waitingForUpdateCounter, setWaitingForUpdateCounter] = useState(null);

	useEffect(() => {
		checkProcessedProducts();
	}, []);

	const handleNewSetFileChange = (file) => {
		setNewSetFile(file);
		console.log(file.name);
		// setLoading(true);
	};

	const handleUploadtFileChange = (file) => {
		setUploadFile(file);
		console.log(file.name);
		// setLoading(true);
	};

	const handleDataFileChange = (file) => {
		setDataFile(file);
		console.log(file.name);
	};

	const processNewFile = () => {
		const formData = new FormData();
		formData.append("file", newSetFile);
		formData.append("provider", providerNew);
		console.log("processNewFile");

		setLoading(true);

		const headers = {
			"Api-Key": "alex2024",
		};

		axios
			.post(apiConfig["newFile"], formData, {
				headers: headers,
			})
			.then((result) => {
				console.log("result new products: ", result.data);
				if (result && result.data.products) {
					console.log(result);
					setNewProducts(result.data.products.newProducts);
					setUpdatedProducts(result.data.products.updatedProducts);
				}

				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const processUploadFile = () => {
		const formData = new FormData();
		formData.append("file", uploadFile);
		formData.append("provider", providerUpload);

		setLoading(true);

		const headers = {
			"Api-Key": "alex2024",
		};

		axios
			.post(apiConfig["uploadFile"], formData, {
				headers: headers,
			})
			.then((result) => {
				if (result) {
					console.log(result);
				}

				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const processDataFile = () => {
		const formData = new FormData();
		formData.append("file", dataFile);

		setLoading(true);

		const headers = {
			"Api-Key": "alex2024",
		};

		console.log("send request to update data");

		axios
			.post(apiConfig["updateData"], formData, {
				headers: headers,
			})
			.then((result) => {
				if (result) {
					console.log(result);
					setDataProcessedProductsCounter(result.data.saveCounter);
				}

				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const handleProviderNewChange = (event) => {
		setProviderNew(event.target.value);
	};

	const handleProviderUploadChange = (event) => {
		setProviderUpload(event.target.value);
	};

	const checkProcessedProducts = () => {
		setLoading(true);
		console.log("checkProcessedProducts");
		const headers = {
			"Api-Key": "alex2024",
		};

		axios
			.post(apiConfig["checkProcessed"], "", {
				headers: headers,
			})
			.then((result) => {
				// console.log(result);
				if (result && result.data.success) {
					console.log(result, result.data.counter, result.data.products.length);
					setDataProcessedProductsCounter(result.data.counter);
					setDataUpdatedProducts(result.data.products);
				}

				setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
	};

	const deleteProcessedProducts = () => {
		// setLoading(true);
		// console.log("checkProcessedProducts");
		// const headers = {
		// 	"Api-Key": "alex2024",
		// };
		// axios
		// 	.post(apiConfig["checkProcessed"], "", {
		// 		headers: headers,
		// 	})
		// 	.then((result) => {
		// 		// console.log(result);
		// 		if (result && result.data.success) {
		// 			console.log(result);
		// 		}
		// 		setLoading(false);
		// 	})
		// 	.catch((error) => {
		// 		console.log(error);
		// 		setLoading(false);
		// 	});
	};

	return (
		<>
			<div className="files-body-wrapper">
				<Grid container spacing={1}>
					<Grid xs={6}>
						<h2>Check the new products</h2>
						<div className="file-section-wrapper">
							<FormControl className="provider-select">
								<InputLabel id="demo-simple-select-label">Shop</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={providerNew}
									label="Age"
									onChange={handleProviderNewChange}
								>
									<MenuItem value={"DELIFE.EU"}>DELIFE.EU</MenuItem>
									<MenuItem value={"Die Möbelfundgrube.DE"}>
										Die Möbelfundgrube.DE
									</MenuItem>
									<MenuItem value={"Hagebau.DE"}>Hagebau.DE</MenuItem>
									<MenuItem value={"VidaXL DE"}>VidaXL DE</MenuItem>
								</Select>
							</FormControl>

							{!newSetFile ? (
								<FileUploader
									handleChange={handleNewSetFileChange}
									name="newSetFile"
									types={fileTypes}
									disabled={!providerNew}
								/>
							) : (
								<>
									<span className="file-upload-text">
										Process ${newSetFile.name}
									</span>
									<div className="file-upload-btn-wrapper">
										{!newProducts ? (
											<Button variant="contained" onClick={processNewFile}>
												Process
											</Button>
										) : (
											""
										)}
									</div>
									{newProducts && updatedProducts ? (
										<div className="file-upload-results-wrapper">
											<span className="file-upload-results-text">
												<b>-> Found {newProducts.length} new product(s)</b>
											</span>

											<CSVLink
												data={newProducts}
												filename={`newProducts-${moment().format(
													"MM-D-YY_hh-mm-ss"
												)}`}
											>
												<Button variant="contained">
													{`Download newProducts-${moment().format(
														"MM-D-YY_hh-mm-ss"
													)}.csv`}
												</Button>
											</CSVLink>
											<span className="file-upload-results-text">
												<b>
													-> Found {updatedProducts.length} updated product(s)
												</b>
											</span>

											<CSVLink
												data={updatedProducts}
												filename={`updatedProducts-${moment().format(
													"MM-D-YY_hh-mm-ss"
												)}`}
											>
												<Button variant="contained">
													{`Download updatedProducts-${moment().format(
														"MM-D-YY_hh-mm-ss"
													)}.csv`}
												</Button>
											</CSVLink>
										</div>
									) : (
										""
									)}
								</>
							)}
						</div>
					</Grid>

					<Grid xs={6}>
						<h2>Upload and update products</h2>
						<div className="file-section-wrapper">
							<FormControl className="provider-select">
								<InputLabel id="demo-simple-select-label">Shop</InputLabel>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={providerUpload}
									label="Age"
									onChange={handleProviderUploadChange}
								>
									<MenuItem value={"DELIFE"}>DELIFE</MenuItem>
									<MenuItem value={"Die Möbelfundgrube.DE"}>
										Die Möbelfundgrube.DE
									</MenuItem>
									<MenuItem value={"Hagebau.DE"}>Hagebau.DE</MenuItem>
								</Select>
							</FormControl>
							{!uploadFile ? (
								<FileUploader
									handleChange={handleUploadtFileChange}
									name="uploadFile"
									types={fileTypes}
									disabled={!providerUpload}
								/>
							) : (
								<Button variant="contained" onClick={processUploadFile}>
									Process
								</Button>
							)}
						</div>
					</Grid>
					<Grid xs={6}>
						<h2>Process and update data</h2>
						<div className="file-process-counter-wrapper">
							<p>
								Waiting to be processed products: {dataProcessedProductsCounter}
							</p>
							<Button variant="outlined" onClick={checkProcessedProducts}>
								Refresh
							</Button>
							<p>
								Already processed products:{" "}
								{dataUpdatedProducts ? dataUpdatedProducts.length : ""}
							</p>
							{dataUpdatedProducts && dataUpdatedProducts.length > 0 ? (
								<>
									<CSVLink
										data={dataUpdatedProducts}
										filename={`processedProducts-${moment().format(
											"MM-D-YY_hh-mm-ss"
										)}`}
									>
										<Button variant="contained">
											{`Download processedProducts-${moment().format(
												"MM-D-YY_hh-mm-ss"
											)}.csv`}
										</Button>
									</CSVLink>
									<Button
										variant="outlined"
										onClick={deleteProcessedProducts}
										style={{ marginTop: "10px" }}
									>
										Delete Processed Products
									</Button>
								</>
							) : (
								""
							)}
						</div>
						<div className="file-section-wrapper">
							{!dataFile ? (
								<FileUploader
									handleChange={handleDataFileChange}
									name="DataProcessingFile"
									types={fileTypes}
								/>
							) : (
								<>
									{dataProcessedProductsCounter ||
									dataProcessedProductsCounter === 0 ? (
										<div className="file-upload-results-wrapper">
											<span className="file-upload-results-text">
												<b>
													-> Updated {dataProcessedProductsCounter} products
													data
												</b>
											</span>
										</div>
									) : (
										<>
											<span className="file-upload-text">
												Process {dataFile.name}
											</span>
											<div className="file-upload-btn-wrapper">
												<Button variant="contained" onClick={processDataFile}>
													Process
												</Button>
											</div>
										</>
									)}
								</>
							)}
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default Files;
