import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import * as ApiRequest from "../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const ProviderCreate = (props) => {
	const [loading, setLoading] = useRecoilState(loadingAtom);
	const [providerSettings, setProviderSettings] = useState({});

	const host = "CreateProvider";

	const endpointPurposes = {
		create: "create",
	};

	const handleChange = (e) => {
		console.log(e.target.id);
		setProviderSettings({
			...providerSettings,
			[e.target.id]: e.target.value,
		});
	};

	const handleCancel = () => {
		setProviderSettings({});
		window.location.href = "/providers";
	};

	const handleCreate = () => {
		console.log("create");
		setLoading(true);
		let request = {
			purpose: endpointPurposes.get,
			provider: providerSettings,
		};

		ApiRequest.apiRequest(host, request).then((result) => {
			if (result.success) {
				console.log(result);

				setLoading(false);
			} else {
				console.log(result);
				setLoading(false);
			}
		});
	};

	return (
		<>
			<div className="files-body-wrapper">
				<Grid container spacing={1}>
					<Grid xs={12}>
						<h2>Setup provider</h2>
						<p>
							Please put the names of the corresponding columns in the CSV files
							provided by this network
						</p>
					</Grid>
					<Grid xs={12}>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="name"
									label="provider name"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="title"
									label="title"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="category"
									label="category"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="description"
									label="description"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="short_description"
									label="short_description"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="regular_price"
									label="regular_price"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="external_url"
									label="external_url"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="button_text"
									label="button_text"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="images"
									label="images"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="stock_status"
									label="stock_status"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-field-wrapper">
							<div className="form-field-field">
								<TextField
									id="colour"
									label="colour"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
						</div>
						<div className="form-actions">
							<Button variant="outlined" onClick={handleCancel}>
								Cancel
							</Button>
							<Button variant="contained" onClick={handleCreate}>
								Create
							</Button>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default ProviderCreate;
