import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const Providers = (props) => {
	const [] = useState();

	return (
		<>
			<div className="files-body-wrapper">
				<Grid container spacing={1}>
					<Grid xs={12}>
						<h2>Add provider</h2>
						<Link to="/providers/create">
							<Button variant="contained">Create</Button>
						</Link>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default Providers;
