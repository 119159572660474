import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const Nav = (props) => {
	const [] = useState();

	return (
		<>
			<AppBar position="static" className="navigation-bar-wrapper">
				<div className="navigation-box-wrapper">
					<div className="navigation-left-wrapper">
						<ul className="navigation-menu-wrapper">
							<li className="navigation-menu-item">
								<a href="/">CSV upload</a>
							</li>
							<li className="navigation-menu-item">
								<a href="/providers">Providers</a>
							</li>
							<li className="navigation-menu-item">
								<a href="/shops">Shops</a>
							</li>
						</ul>
					</div>
				</div>
			</AppBar>
		</>
	);
};

export default Nav;
