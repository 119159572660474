import logo from "./logo.svg";
import "./App.css";
import Files from "./Components/Files";
import Loader from "./Components/main/Loader";
import { RecoilRoot } from "recoil";
import Router from "./Components/Router";
import { RouterProvider } from "react-router-dom";

function App() {
	return (
		<>
			<RecoilRoot>
				<div className="App">
					{/* <Files /> */}
					<RouterProvider router={Router} />
				</div>
				<Loader />
			</RecoilRoot>
		</>
	);
}

export default App;
