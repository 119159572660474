import React, { useState } from "react";
import Nav from "../main/Nav";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
	const [] = useState();

	return (
		<>
			<Nav />
			<Outlet />
		</>
	);
};

export default Layout;
