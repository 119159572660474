const host = "http://localhost:8088";
// const host = "https://iqonix-198830e4d691.herokuapp.com";

const apiConfig = {
	Main: `${host}`,
	newFile: `${host}/file/processnew`,
	uploadFile: `${host}/file/processupload`,
	updateData: `${host}/file/processdata`,
	checkProcessed: `${host}/file/checkprocessed`,
};

export default apiConfig;
