import {
	createBrowserRouter,
	BrowserRouter as Router,
	Route,
	Routes,
} from "react-router-dom";
import Layout from "./main/Layout";
import Files from "./Files";
import Shops from "./Pages/Shops";
import Providers from "./Pages/Providers";
import ProviderCreate from "./Pages/ProviderCreate";
import ShopCreate from "./Pages/ShopCreate";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout />,
		children: [
			{
				index: true,
				element: <Files />,
			},
			{
				path: "/shops",
				element: <Shops />,
			},
			{
				path: "/shops/create",
				element: <ShopCreate />,
			},
			{
				path: "/providers",
				element: <Providers />,
			},
			{
				path: "/providers/create",
				element: <ProviderCreate />,
			},
		],
	},
]);

export default router;
