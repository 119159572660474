import axios from "axios";
import apiConfig from "./apiConfig";

export const apiRequest = (purpose, payload) => {
	// console.log({
	// 	apiKey: apiConfig.key,
	// 	request: payload,
	// });

	return axios
		.post(
			apiConfig[purpose],
			{
				apiKey: apiConfig.key,
				request: payload,
			},
			{
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			if (error.response.status == 401) {
				console.log("error response 401", error.response, apiConfig[purpose]);
				// return redirect("/login");
			} else {
				return error;
			}
		});
	// ).then(res => {
	//         result = res.data;
	//     });

	// const data = await res.data;
	// return data;
};
