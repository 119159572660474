import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import * as ApiRequest from "../api/apiRequest";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../atoms/atoms";

const ShopCreate = (props) => {
	const [] = useState();

	const [shopSettings, setShopSettings] = useState({});

	const host = "CreateShop";

	const endpointPurposes = {
		create: "create",
	};

	const handleChange = (e) => {
		console.log(shopSettings);
		setShopSettings({
			...shopSettings,
			[e.target.id]: e.target.value,
		});
	};

	const handleCancel = () => {
		setShopSettings({});
		window.location.href = "/shops";
	};

	const handleCreate = () => {
		console.log("create");
	};

	return (
		<>
			<div className="files-body-wrapper">
				<Grid container spacing={1}>
					<Grid xs={12}>
						<h2>Setup provider</h2>
						<p>
							Please put the names of the corresponding columns in the CSV files
							provided by this network
						</p>
					</Grid>
					<Grid xs={6}>
						<div className="form-column-wrapper">
							<div className="form-field-field">
								<TextField
									id="shop_name"
									label="Shop Name"
									variant="standard"
									onChange={handleChange}
								/>
							</div>
							<h3>Fields matching</h3>
							<p>
								Please put the names of the corresponding columns in the CSV
								files provided by this network
							</p>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="name"
										label="name"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="identifier"
										label="identifier"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="deeplink"
										label="deeplink"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="description"
										label="description"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="sku"
										label="sku"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="price"
										label="price"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="sale_price"
										label="sale_price"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="images"
										label="images"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="stock_status"
										label="stock_status"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="EAN"
										label="EAN"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="brand"
										label="brand"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="delivery"
										label="delivery"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="colors"
										label="colors"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="category"
										label="category"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
							<div className="form-field-wrapper">
								<div className="form-field-field">
									<TextField
										id="dimensions"
										label="dimensions"
										variant="standard"
										onChange={handleChange}
									/>
								</div>
							</div>
						</div>
					</Grid>
					<Grid xs={3}>
						<div className="form-column-wrapper">
							<h3>Dimension groups</h3>
							<p>
								Please put the dimension groups and the display names of the
								groups
							</p>
						</div>
					</Grid>
					<Grid xs={3}>
						<div className="form-column-wrapper">
							<h3>Delivery groups</h3>
							<p>
								Please put the delivery groups and the display names of the
								groups
							</p>
						</div>
					</Grid>

					<div className="form-actions">
						<Button variant="outlined" onClick={handleCancel}>
							Cancel
						</Button>
						<Button variant="contained" onClick={handleCreate}>
							Create
						</Button>
					</div>
				</Grid>
			</div>
		</>
	);
};

export default ShopCreate;
