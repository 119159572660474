const host = "http://localhost:8088";
// const host = "https://iqonix-198830e4d691.herokuapp.com/";

const apiConfig = {
	key: "serverIqonix",
	CreateProvider: `${host}/providers/create/`,
	CreateShop: `${host}/shops/create/`,
};

export default apiConfig;
